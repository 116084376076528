import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions } from 'features/users/redux';
import { actions as treeActions } from 'features/tree';

const useTree = (isMobile = false) => {
  const dispatch = useDispatch();
  const { selectedAgentId } = useSelector(state => state.tree, shallowEqual);

  const selectAgent = useCallback(
    id => {
      dispatch(treeActions.selectAgentId(id));
    },
    [dispatch],
  );

  const getUsers = data => {
    return dispatch(actions.getUsersList(data));
  };

  const getTreeById = name => {
    const data = name.length ? { username: name } : { userId: selectedAgentId };

    return getUsers(data).then(data => {
      const targetUser = data?.users.find(i => i?.username === name);
      if (targetUser) {
        const [targetId, targetName] =
          +targetUser.role === 0
            ? [targetUser.parentAgent.id, targetUser.parentAgent.username]
            : [targetUser.id, targetUser.username];

        dispatch(treeActions.getUsersByUserId({ id: targetId, nesting: [targetId], name: targetName }, true)).finally(
          () => {
            if (!isMobile) selectAgent(targetId);
          },
        );
      }

      return data?.upperParent;
    });
  };

  return { getTreeById };
};

export default useTree;
