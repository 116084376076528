import React, { useCallback, useEffect, useState } from 'react';
import { block } from 'bem-cn';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { actions } from 'features/users/redux';
import SVG from 'components/SVG';
import useTree from 'hooks/useTree';
import searchSvg from './img/search.svg';
import SearchInput from 'components/SearchInput';

import './UsersFilterBlock.scss';

const b = block('users-filter-block');

const UsersFilterBlock = ({
  filterValue,
  changeFilterValue,
  callBack = null,
  actionProcessing,
  withoutSearchIcon = false,
  placeholder = null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const locale = useSelector(state => state.locale.locale);
  const { getTreeById } = useTree();

  const [searchResults, setSearchResults] = useState(null);

  const getUsers = useCallback(
    data => {
      return dispatch(actions.getUsersListForSearch(data));
    },
    [dispatch],
  );

  const handleSearch = useCallback(
    async value => {
      changeFilterValue(value);
      const params = new URLSearchParams(location.search);
      if (value) {
        params.set('username', value);
      } else {
        params.delete('username');
      }
      history.replace({ search: params.toString() });

      if (value) {
        try {
          const data = await getUsers({ username: value });
          setSearchResults(data);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [changeFilterValue, getUsers, history, location.search],
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const savedFilter = params.get('username');
    if (savedFilter) {
      changeFilterValue(savedFilter);
      handleSearch(savedFilter);
    }
  }, [changeFilterValue, handleSearch, location.search]);

  useEffect(() => {
    changeFilterValue('');
  }, []);

  const activeCallback = useCallback(() => {
    if (!filterValue) {
      history.replace({ search: '' });
    }
    if (callBack) return callBack();
    return getTreeById(filterValue || '');
  }, [callBack, filterValue, getTreeById]);

  const onButtonClick = useCallback(
    event => {
      if (event.key === 'Enter') {
        activeCallback();
      }
    },
    [activeCallback],
  );

  return (
    <div className={b()}>
      <div className={b('input')}>
        <SearchInput
          value={filterValue}
          items={searchResults}
          onChange={handleSearch}
          placeholder={placeholder ?? `${locale.search} ${locale.user}`}
          onKeyDown={event => onButtonClick({ event, type: 'keydown' })}
        />
      </div>
      {!withoutSearchIcon && (
        <button disabled={actionProcessing} className={b('icon-wrapper')} onClick={activeCallback}>
          <SVG svgProps={{ svg: searchSvg }} className={b('icon').toString()} />
        </button>
      )}
    </div>
  );
};

export default UsersFilterBlock;
