import React from 'react';
import block from 'bem-cn';
import './TableHead.scss';

const TableHead = ({ items, locale, type }) => {
  const b = block('table-head-pagination');

  return (
    <div className={b()}>
      {items?.map(el => (
        <div key={el} className={b('table-head', { type })}>
          {locale[el]}
        </div>
      ))}
    </div>
  );
};

export default TableHead;
