import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import './TableRow.scss';

const b = block('table-row-pagination');

const TableRow = ({ items, headers }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  return (
    <div className={b('tr')}>
      {items?.map((el, index) => {
        const isHistory = headers[index] === 'status' || headers[index] === 'type';

        if (typeof el === 'object' && el.length > 0) {
          return (
            <div key={`${el}_${index}`} className={b('td', { status: isHistory && el })}>
              <div className={b('user-block')}>
                {el.map((part, partIndex) => (
                  <span key={`${part}_${partIndex}`}>
                    {el[0] === part ? `${locale.id}: ${part}` : part}
                  </span>
                ))}
              </div>
            </div>
          );
        } else {
          return (
            <div key={`${el}_${index}`} className={b('td', { status: isHistory && el })}>
              <span style={{ wordWrap: 'break-word' }}>{isHistory ? locale.statuses[el] : index > 1 ? formatNumberWithLimit(el) : el}</span>
            </div>
          );
        }
      })}
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
