import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export class ReportsApiConverter {
  convertStatisticProvider = data => {
    let arrProviders = [];
    arrProviders = Object.keys(data.providers)
      .filter((el, index) => /* el !== 'OwnerOperations' &&  */ el !== 'null')
      .map((el, index) => ({
        provider: el,
        betCount: data.providers[el].bet_count,
        betAmount: data.providers[el].bet_amount,
        winAmount: data.providers[el].win_amount,
        incomeAmount: data.providers[el].income_amount,
      }));

    return {
      providers: arrProviders,
      detail: data.detail,
    };
  };

  convertPaymentHistory = data => {
    let arrTransfers = [];
    arrTransfers = data.transfers.map(el => {
      return {
        id: el?.id,
        operation: el?.operation,
        amount: el?.amount,
        fromUser: el?.from_user,
        fromUserId: el?.from_user_id,
        toUser: el?.to_user,
        toUserId: el?.to_user_id,
        initiatorUser: el?.initiator_user,
        initiatorUserId: el?.initiator_user_id,
        realToUser: el?.real_to_user,
        // createdAt: dayjs(el?.created_at).format('DD.MM.YY'),
        createdAt: el?.created_at,
        balanceFrom: el?.balance_changes.balance_from,
        balanceTo: el?.balance_changes.balance_to,
      };
    });

    return {
      transfers: arrTransfers,
      details: {
        totalCount: data.details.total_count,
        totalDeposit: data.details.total_deposit,
        totalWithdraw: data.details.total_withdraw,
        deltaBalance: data.details.delta_balance,
      },
    };
  };

  convertStatisticPlayer = data => {
    let arrStatistics = [];

    arrStatistics = Object.keys(data.user_statistic).map(el => ({
      id: el,
      name: data.user_statistic[el].email,
      amountIn: data.user_statistic[el].amount_in,
      amountOut: data.user_statistic[el].amount_out,
      total: data.user_statistic[el].total,
      role: data.user_statistic[el].role,
    }));

    return {
      userStatistic: arrStatistics,
      details: {
        totalCount: data.details.total_count,
      },
    };
  };

  convertSlotsHistory = data => {
    let arrData = [];
    arrData = data.data.map(el => ({
      id: el.id,
      // date: `${dayjs(el.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YY')} ${dayjs(el.date, 'YYYY-MM-DD HH:mm:ss').add(-3, 'hour').format('HH:mm:ss')}`,
      date: `${dayjs(el.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YY HH:mm:ss')}`,
      player: [el.user_id, el.username],
      provider: el.provider,
      amount: el.amount,
      prevBalance: el.balance_before,
      afterBalance: el.balance_after,
      status: el.status,
      // userId: el.user_id,
      // gameType: el.gameType,
    }));

    return {
      data: arrData,
      totalCount: data.totalCount,
    };
  };

  convertBetsHistory = data => {
    let arrData = [];
    arrData =
      data?.items?.map(el => ({
        couponId: el.id,
        userId: el.user_id,
        date: dayjs(el.date).format('DD.MM.YY'),
        // user: el.username,
        // type: el.status,
        type: el.is_express ? 'express' : 'single',

        amount: el.amount,
        currency: 'ARS',
        balanceBefore: el.balanceAfter ?? 0,
        balanceAfter: el.balanceBefore ?? 0,
        totalCoeff: el.coef,
        possibleGain: el.possible_win_amount,

        status: el.status,
        // userId: el.user_id,
        isExpress: el.is_express,
        // currency: el.currency,
        // bets: el.bets,
        // winAmount: el.win_amount,
      })) || [];
    // {
    //   "id": 1,
    //     "status": "ACCEPTED",
    //       "user_id": 4,
    //         "is_express": false,
    //           "coef": 1.82,
    //             "amount": 20,
    //               "created_at": "2024-02-12",
    //                 "possible_win_amount": 36.4
    // }
    return {
      data: arrData,
      count: data.count,
    };
  };

  convertDetailsBetsHistory = data => {
    return {
      data,
    };
  };

  convertFinanceHistory = data => {
    let arrTransfers = [];
    arrTransfers = data.transfers.map(el => ({
      id: el?.id,
      operation: el?.operation === 0 ? 'deposit' : 'withdrawal',
      // fromUserId: el?.from_user_id,
      // toUserId: el?.to_user_id,
      fromUser: el?.from_user,
      toUser: el?.to_user,
      currency: el?.currency,
      createdAt: dayjs(el?.created_at).format('DD.MM.YY'),
      balanceBeforeFromUser: el?.additional_data.balance_before_from_user,
      balanceBeforeToUser: el?.additional_data.balance_before_to_user,
      balanceAfterFromUser: el?.additional_data.balance_after_from_user,
      balanceAfterToUser: el?.additional_data.balance_after_to_user,
    }));

    return {
      transfers: arrTransfers,
      details: {
        totalCount: data.details.total_count,
      },
    };
  };
}
