import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import block from 'bem-cn';
import { useParams } from 'react-router';

import Button from 'components/Button';
import { getTitlePageFromRoute } from '../../data';

import './Subheader.scss';
import UsersFilterBlock from 'features/users/view/desktop/UsersFilterBlock';

const b = block('subheader');

const Subheader = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const lastSearchedUsername = useMemo(() => {
    const params = new URLSearchParams(location.search);

    return params.get('username');
  }, [location.search]);

  const locale = useSelector(state => state.locale.locale);
  const role = useSelector(state => state.auth.role);
  const [actionProcessing, changeActionProcessing] = useState(false);
  const [filterValue, onChangeFilterValue] = useState(lastSearchedUsername || '');
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);

  const { tab } = useParams();

  const auth = useSelector(state => state.auth);
  const { isNeedToRefresh } = useSelector(state => state.users);
  const { selectedAgentId } = useSelector(state => state.tree);

  const { email, id } = auth;

  const isBanned = useMemo(() => {
    if (tab === 'disabled') {
      return true;
    } else return false;
  }, [tab]);

  const changeFilterValue = useCallback(value => {
    onChangeFilterValue(value.toLowerCase());
    changeActionProcessing(false);
  }, []);

  useEffect(() => {
    setPage(0);
  }, [selectedAgentId]);

  const getTitle = () => {
    return getTitlePageFromRoute.find(el => location.pathname.includes(el.route));
  };

  const currentItem = getTitle();

  const effectiveTitle =
    currentItem?.title === 'playerAndAgentDetail' && role === 6 ? 'playerDetail' : currentItem?.title;

  if (currentItem?.exception && location.pathname.includes(currentItem.exception)) return null;

  return (
    <div className={b()}>
      <div className={b('second-title')}>{location.pathname === '/' ? '' : locale[effectiveTitle]}</div>
      {location.pathname.includes('/users') && (
        <div className={b('filters')}>
          {/* <UsersFilterBlock
            actionProcessing={actionProcessing}
            filterValue={filterValue}
            changeFilterValue={changeFilterValue}
          />
          <div className={b('separator')} /> */}
          <div className={b('buttons')}>
            {role === 6 && <Button link="/user/create-agent" text={locale.newAgent} />}
            <Button color="transparent" link="/user/create-player" text={locale.newPlayer} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Subheader;
