import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export class AuthConverter {
  convertUserData(data) {
    const convertedData = {
      id: data.id,
      email: data.email,
      currency: data.currency,
      firstName: data.first_name,
      lastName: data.last_name,
      role: +data.role,
      isPartner: data.is_partner,
      typePartnerPayment: data.type_partner_payment,
      isWithdrawalAccess: data.is_withdrawal_access,
      isDepositAccess: data?.is_deposit_access ?? true,
      username: data.username,
    };
    return convertedData;
  }

  convertBalance(data) {
    const convertedData = {
      balance: data.balance,
      bonusBalance: +data.bonus_balance,
      currency: data.currency,
      isBanned: data.is_banned,
    };
    return convertedData;
  }

  convertNotifications(data) {
    const responseData = data?.payment_notifications;

    if (!responseData) return [];

    return Object.values(responseData)
      .map((notice, idx) => ({
        id: idx + 1,
        isNew: false,
        date: notice?.date
          ? dayjs(notice.date.split('.')[0], 'YYYY-MM-DD HH:mm:ss').subtract(3, 'hour').format('DD.MM.YYYY HH:mm:ss')
          : null,
        type: notice?.type,
        amount: notice?.amount,
        fromId: notice?.from_id,
        currency: notice?.currency,
      }))
      .sort(
        (a, b) =>
          dayjs(b.date, 'DD.MM.YYYY HH:mm:ss').toDate().valueOf() -
          dayjs(a.date, 'DD.MM.YYYY HH:mm:ss').toDate().valueOf(),
      )
      .valueOf();
  }
}
